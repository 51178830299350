/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { create as e, contains as i } from "../../../geometry/support/aaBoundingRect.js";
import t from "./TileCache.js";
import s from "./TileCoverage.js";
import l from "./TileKey.js";
const o = new l(0, 0, 0, 0),
  h = new Map(),
  a = [],
  c = [];
class r {
  constructor(e) {
    this._previousScale = Number.POSITIVE_INFINITY, this.cachePolicy = "keep", this.coveragePolicy = "closest", this.resampling = !0, this.tileIndex = new Map(), this.tiles = [], this.buffer = 192, this.acquireTile = e.acquireTile, this.releaseTile = e.releaseTile, this.tileInfoView = e.tileInfoView, null != e.resampling && (this.resampling = e.resampling), e.cachePolicy && (this.cachePolicy = e.cachePolicy), e.coveragePolicy && (this.coveragePolicy = e.coveragePolicy), null != e.buffer && (this.buffer = e.buffer), e.cacheSize && (this._tileCache = new t(e.cacheSize, this.tileInfoView, e => {
      this.releaseTile(e);
    }));
  }
  destroy() {
    this.tileIndex.clear();
  }
  update(e) {
    const {
        resampling: i,
        tileIndex: t
      } = this,
      {
        scale: l,
        center: r,
        resolution: n
      } = e.state,
      {
        minScale: f,
        maxScale: d
      } = this.tileInfoView,
      u = !e.stationary && l > this._previousScale;
    if (this._previousScale = l, !i && (l > f || l < d)) return this.tiles.length = 0, void this.clear();
    const p = this.tileInfoView.getTileCoverage(e.state, this.buffer, this.resampling, this.coveragePolicy);
    if (!p) return this.tiles.length = 0, void this.clear();
    const {
        spans: y,
        lodInfo: g
      } = p,
      {
        level: I
      } = g;
    this.tiles.length = 0, t.forEach(e => e.visible = !0);
    let v = 0,
      m = 0;
    if (y.length > 0) for (const {
      row: s,
      colFrom: a,
      colTo: c
    } of y) for (let e = a; e <= c; e++) {
      v++;
      const i = o.set(I, s, g.normalizeCol(e), g.getWorldForColumn(e)).id;
      let l = t.get(i);
      if (l) l.isReady ? (h.set(i, l), m++) : u || this._addParentTile(i, h);else {
        if (this._tileCache?.has(i)) {
          if (l = this._tileCache.pop(i), this.tileIndex.set(i, l), l.isReady) {
            h.set(i, l), m++;
            continue;
          }
        } else l = this.acquireTile(o), this.tileIndex.set(i, l);
        u || this._addParentTile(i, h);
      }
    }
    const T = m === v;
    for (const [s, _] of t) {
      if (h.has(s)) continue;
      o.set(s);
      const e = this.tileInfoView.intersects(p, o),
        i = "purge" === this.cachePolicy ? o.level !== I : o.level > I;
      !e || !u && T ? !i && e || a.push(_) : _.isReady ? i && "purge" === this.cachePolicy && this._hasReadyAncestor(o, I) ? a.push(_) : c.push(_) : i && a.push(_);
    }
    for (const s of c) s.isReady && h.set(s.key.id, s);
    for (const s of a) this._tileCache ? this._tileCache.add(s) : this.releaseTile(s), t.delete(s.key.id);
    for (const s of h.values()) this.tiles.push(s);
    for (const s of t.values()) h.has(s.key.id) || (s.visible = !1);
    this._tileCache?.prune(I, r, n), s.pool.release(p), c.length = 0, a.length = 0, h.clear();
  }
  clear() {
    const {
      tileIndex: e
    } = this;
    for (const i of e.values()) this.releaseTile(i);
    e.clear();
  }
  refresh(e) {
    for (const i of this.tileIndex.values()) this.tiles.includes(i) ? e(i) : a.push(i);
    for (const i of a) this.releaseTile(i), this.tileIndex.delete(i.key.id);
    this._tileCache?.clear();
  }
  updateCacheSize(e) {
    this._tileCache && (this._tileCache.maxSize = e);
  }
  _addParentTile(e, i) {
    let t = e,
      s = null;
    for (; t = this.tileInfoView.getTileParentId(t), t;) if (this.tileIndex.has(t)) {
      if (s = this.tileIndex.get(t), s?.isReady) {
        i.has(s.key.id) || i.set(s.key.id, s);
        break;
      }
    } else if (this._tileCache?.has(t) && (s = this._tileCache.pop(t), this.tileIndex.set(t, s), s?.isReady)) {
      i.has(s.key.id) || i.set(s.key.id, s);
      break;
    }
  }
  _hasReadyAncestor(t, s) {
    const l = e();
    this.tileInfoView.getTileBounds(l, t, !0);
    for (const o of this.tileIndex.values()) if (o.isReady && o.key.level >= s && o.key.level < t.level) {
      const t = e();
      if (this.tileInfoView.getTileBounds(t, o.key, !0), i(t, l)) return !0;
    }
    return !1;
  }
}
export { r as default };