/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../../chunks/tslib.es6.js";
import t from "../../../core/Accessor.js";
import { destroyMaybe as s } from "../../../core/maybe.js";
import { property as r } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as o } from "../../../core/accessorSupport/decorators/subclass.js";
import { distance as i } from "../../../core/libs/gl-matrix-2/math/vec2.js";
import { QueueProcessor as n } from "../../support/QueueProcessor.js";
function c(e, t) {
  return e.length = 0, t.forEach(t => e.push(t)), e;
}
const u = new Set(),
  a = [],
  h = new Map(),
  l = [0, 0];
let p = class extends t {
  constructor(e) {
    super(e), this._keyToItem = new Map(), this.concurrency = 6, this.strategy = "scale-first", this.tileInfoView = null;
  }
  initialize() {
    const {
      concurrency: e,
      process: t,
      strategy: s
    } = this;
    this._queue = new n({
      concurrency: e,
      process: (e, s) => {
        const r = this._keyToItem.get(e);
        return t(r, {
          signal: s
        });
      },
      peeker: "scale-first" === s ? e => this._peekByScaleFirst(e) : e => this._peekByCenterFirst(e)
    });
  }
  destroy() {
    this.clear(), this._queue = s(this._queue);
  }
  get length() {
    return this._queue ? this._queue.length : 0;
  }
  get onGoingCount() {
    return this._keyToItem.size;
  }
  abort(e) {
    const t = "string" == typeof e ? e : e.id;
    this._queue.abort(t);
  }
  clear() {
    this._queue.clear(), this._keyToItem.clear();
  }
  has(e) {
    return "string" == typeof e ? this._keyToItem.has(e) : this._keyToItem.has(e.id);
  }
  isOngoing(e) {
    const t = "string" == typeof e ? e : e.id;
    return this.has(t) && this._queue.isOngoing(t);
  }
  pause() {
    this._queue.pause();
  }
  push(e) {
    const t = e.key.id;
    if (this._queue.has(t)) return this._queue.get(t);
    const s = this._queue.push(t),
      r = () => {
        this._keyToItem.delete(t);
      };
    return this._keyToItem.set(t, e), s.then(r, r), s;
  }
  reset() {
    this._queue.reset();
  }
  resume() {
    this._queue.resume();
  }
  _peekByScaleFirst(e) {
    if (!this.state) return e.values().next().value;
    const t = this.tileInfoView;
    let s = Number.NEGATIVE_INFINITY,
      r = Number.POSITIVE_INFINITY;
    e.forEach(e => {
      const t = this._keyToItem.get(e),
        o = this.tileInfoView.getTileScale(t.key);
      h.has(o) || (h.set(o, []), s = Math.max(o, s), r = Math.min(o, r)), h.get(o).push(t.key), u.add(o);
    });
    let o = this.state.scale;
    h.has(o) || (c(a, u), a.sort((e, t) => e - t), o = a.reduce((e, t) => Math.abs(t - o) < Math.abs(e - o) ? t : e, a[0])), o = Math.min(o, s), o = Math.max(o, r);
    const i = h.get(o),
      n = t.getClosestInfoForScale(o),
      l = n.getColumnForX(this.state.center[0]),
      p = n.getRowForY(this.state.center[1]);
    return i.sort((e, t) => {
      const s = n.denormalizeCol(e.col, e.world),
        r = n.denormalizeCol(t.col, t.world);
      return Math.sqrt((l - s) * (l - s) + (p - e.row) * (p - e.row)) - Math.sqrt((l - r) * (l - r) + (p - t.row) * (p - t.row));
    }), u.clear(), h.clear(), i[0].id;
  }
  _peekByCenterFirst(e) {
    if (!this.state) return e.values().next().value;
    const t = this.tileInfoView,
      s = this.state.center;
    let r,
      o = Number.POSITIVE_INFINITY;
    return e.forEach(e => {
      const n = this._keyToItem.get(e);
      t.getTileCoords(l, n.key);
      const c = i(l, s);
      c < o && (o = c, r = n.key);
    }), r.id;
  }
};
e([r({
  constructOnly: !0
})], p.prototype, "concurrency", void 0), e([r({
  constructOnly: !0
})], p.prototype, "process", void 0), e([r()], p.prototype, "state", void 0), e([r({
  constructOnly: !0
})], p.prototype, "strategy", void 0), e([r({
  constructOnly: !0
})], p.prototype, "tileInfoView", void 0), p = e([o("esri.views.2d.tiling.TileQueue")], p);
const m = p;
export { m as default };